export class I18nManager {
    constructor() {
      this.availableLanguages = ['en', 'es'];
      this.defaultLanguage = 'en';
      
      this.pageTitles = {
        default: {
          en: 'JoinClans - Unite Gamers and Clans',
          es: 'JoinClans - Une Jugadores y Clanes',
        },
        routeSpecific: {
          Home: {
            en: 'Home - JoinClans',
            es: 'Inicio - JoinClans',
          },
        }
      };
      
      this.pageMetadata = {
        description: {
          en: 'Connect with players and join clans in our gaming community, acquire your ClanHall to organize your players and maintain contact with them at all times totally free',
          es: 'Conéctate con jugadores y únete a clanes en nuestra comunidad de gaming, adquiere tu ClanHall para organizar a tus jugadores y mantener el contacto con ellos en todo momento totalmente gratis',
        },
        // Puedes agregar más metadatos aquí
      };

      this.pageMetadata = {
        description: {
          en: 'Connect with players and join clans in our gaming community, acquire your ClanHall to organize your players and maintain contact with them at all times totally free',
          es: 'Conéctate con jugadores y únete a clanes en nuestra comunidad de gaming, adquiere tu ClanHall para organizar a tus jugadores y mantener el contacto con ellos en todo momento totalmente gratis',
        },
        // Agrega más metadatos específicos si es necesario
        openGraph: {
          title: {
            en: 'JoinClans - Unite Gamers and Clans',
            es: 'JoinClans - Une Jugadores y Clanes',
          },
          image: {
            en: 'https://joinclans.com/joinclans.png', // Cambia la URL a tu imagen
            es: 'https://joinclans.com/joinclans.png', // Cambia la URL a tu imagen
          },
          url: 'https://joinclans.com/',
        },
        twitter: {
          title: {
            en: 'JoinClans - Unite Gamers and Clans',
            es: 'JoinClans - Une Jugadores y Clanes',
          },
          description: {
            en: 'Connect with players and join clans in our gaming community, acquire your ClanHall to organize your players and maintain contact with them at all times totally free',
            es: 'Conéctate con jugadores y únete a clanes en nuestra comunidad de gaming, adquiere tu ClanHall para organizar a tus jugadores y mantener el contacto con ellos en todo momento totalmente gratis',
          },
          image: {
            en: 'https://joinclans.com/joinclans.png', // Cambia la URL a tu imagen
            es: 'https://joinclans.com/joinclans.png', // Cambia la URL a tu imagen
          },
        },
      };
    }

    updatePageMetadata(lang, routeName) {
      if (!this.availableLanguages.includes(lang)) {
        lang = this.defaultLanguage;
      }
  
      // Actualizar título
      const routeTitle = this.pageTitles.routeSpecific[routeName]?.[lang];
      document.title = routeTitle || this.pageTitles.default[lang];
      
      // Actualizar descripción
      this.updateMetaTag('description', this.pageMetadata.description[lang]);
  
      // Actualizar Open Graph
      this.updateMetaTag('og:title', this.pageMetadata.openGraph.title[lang]);
      this.updateMetaTag('og:image', this.pageMetadata.openGraph.image[lang]);
      this.updateMetaTag('og:url', this.pageMetadata.openGraph.url);
      
      // Actualizar Twitter
      this.updateMetaTag('twitter:title', this.pageMetadata.twitter.title[lang]);
      this.updateMetaTag('twitter:description', this.pageMetadata.twitter.description[lang]);
      this.updateMetaTag('twitter:image', this.pageMetadata.twitter.image[lang]);
    }
  
    getSavedLanguage() {
      let localLang = localStorage.getItem('preferredLanguage');
      if (localLang == 'undefined') {
        localLang = 'en';
        localStorage.setItem('preferredLanguage', localLang);
      }
      return this.availableLanguages.includes(localLang) ? localLang : this.defaultLanguage;
    }
  
    setLanguage(lang) {
      if (this.availableLanguages.includes(lang)) {
        localStorage.setItem('preferredLanguage', lang);
        return true;
      }
      return false;
    }
  
    // updatePageMetadata(lang, routeName) {
    //   if (!this.availableLanguages.includes(lang)) {
    //     lang = this.defaultLanguage;
    //   }
  
    //   // Actualizar título
    //   const routeTitle = this.pageTitles.routeSpecific[routeName]?.[lang];
    //   document.title = routeTitle || this.pageTitles.default[lang];
      
    //   // Actualizar descripción
    //   this.updateMetaTag('description', this.pageMetadata.description[lang]);
    // }
  
    updateMetaTag(name, content) {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    }
  
    getRouteTitle(routeName, lang) {
      return this.pageTitles.routeSpecific[routeName]?.[lang] || 
             this.pageTitles.default[lang];
    }
  
    isValidLanguage(lang) {
      return this.availableLanguages.includes(lang);
    }
  
    // Método para agregar nuevos títulos de ruta
    addRouteTitle(routeName, titles) {
      this.pageTitles.routeSpecific[routeName] = titles;
    }
  
    // Método para agregar nuevos metadatos
    addMetadata(key, data) {
      this.pageMetadata[key] = data;
    }
}
export const i18nManager = new I18nManager();