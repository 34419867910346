import { i18nManager } from '../Class/I18manager';

export default async function authMiddleware(to, from, next) {
  let lang = to.params.lang;

  // Comprobación de idioma en la URL, y obtener uno guardado o predeterminado si no existe
  if (!lang) {
    lang = i18nManager.getSavedLanguage();
    return next(`/${lang}${to.fullPath}`);
  }

  // Verificación de idioma válido
  if (!i18nManager.isValidLanguage(lang)) {
    const savedLanguage = i18nManager.getSavedLanguage();
    return next(`/${savedLanguage}${to.fullPath.replace(`/${lang}`, '')}`);
  }

  // Ajuste de idioma predeterminado en localStorage
  const savedLanguage = localStorage.getItem("preferredLanguage");
  if (savedLanguage !== 'en' && savedLanguage !== 'es') {
    localStorage.setItem('preferredLanguage', 'en');
  }

  // Actualización de metadatos de la página
  i18nManager.updatePageMetadata(lang, to.name);

  

  switch (to.name) {
    case 'index':
    case 'explore-clans':
    case 'explore-players':
    case 'item-details':
    case 'item-detail':
    case 'contact':
    case 'aboutus':
    case 'become-creator':
    case 'terms':
    case 'privacy':
    case 'helpcenter-overview':
    case 'helpcenter-faqs':
    case 'helpcenter-guides':
    case 'helpcenter-support':
    case 'blogs':
    case 'blog-details':
    case 'blog-detail':
    case 'comingsoon':
    case 'maintenance':
    case 'thankyou':
    case 'error':
    case 'upload-work':
   

    return next();
     
    default:
      return next({ name: 'index' });
  }
}
