import { createApp } from 'vue';
import App from './App.vue';
import '@/assets/scss/tailwind.scss';
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css";
import '@/assets/libs/@iconscout/unicons/css/line.css';
import router from './router';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import { createPinia } from 'pinia';

const messages = {
  en,
  es
};

const pinia = createPinia();
const i18n = createI18n({
  legacy: false,
  locale: 'en', // Valor por defecto
  fallbackLocale: 'es', // Idioma de respaldo
  messages,
});

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(pinia);
app.mount('#app');
