import { createWebHistory, createRouter } from "vue-router";
import authMiddleware from '../middleware/authMiddleware';

// Función auxiliar para redirigir al dashboard
const redirectToDashboard = (path) => {
  return (to) => {
    const lang = to.params.lang;
    const dashboardUrl = process.env.VUE_APP_DASHBOARD_URL || 'http://localhost:8082';
    console.log(dashboardUrl);
    window.location.href = `${dashboardUrl}/${path}?lang=${lang}`;
  };
};
const routes = [
  {
    path: "/:lang(en|es)", // Define el parámetro de idioma
    // component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: "",
        name: "index",
        component: () => import('@/pages/index/index.vue'),
      },
      {
        path: "explore-clans",
        name: "explore-clans",
        component: () => import('@/pages/explore/explore-clans.vue'),
      },
      {
        path: "explore-players",
        name: "explore-players",
        component: () => import('@/pages/explore/explore-players.vue'),
      },
      {
        path: "item-detail",
        name: "item-details",
        component: () => import('@/pages/explore/item-detail.vue'),
      },
      {
        path: "item-detail/:id",
        name: "item-detail",
        component: () => import('@/pages/explore/item-detail.vue'),
      },
      {
        path: "upload-work", // ruta para aplicar un clan y registrarse a la vez(registro rapido desde clanes)
        name: "upload-work",
        component: () => import('@/pages/explore/upload-work.vue'),
      },
      {
        path: "contact",
        name: "contact",
        component: () => import('@/pages/contact.vue'),
      },
      {
        path: "aboutus",
        name: "aboutus",
        component: () => import('@/pages/aboutus.vue'),
      },
      {
        path: "become-creator",//ruta para patrocinadores
        name: "become-creator",
        component: () => import('@/pages/creators/become-creator.vue'),
      },
      {
        path: "terms",
        name: "terms",
        component: () => import('@/pages/terms.vue'),
      },
      {
        path: "privacy",
        name: "privacy",
        component: () => import('@/pages/privacy.vue'),
      },
      {
        path: "helpcenter-overview",
        name: "helpcenter-overview",
        component: () => import('@/pages/help-center/helpcenter-overview.vue'),
      },
      {
        path: "helpcenter-faqs",
        name: "helpcenter-faqs",
        component: () => import('@/pages/help-center/helpcenter-faqs.vue'),
      },
      {
        path: "helpcenter-guides",
        name: "helpcenter-guides",
        component: () => import('@/pages/help-center/helpcenter-guides.vue'),
      },
      {
        path: "helpcenter-support",
        name: "helpcenter-support",
        component: () => import('@/pages/help-center/helpcenter-support.vue'),
      },
      {
        path: "blogs",
        name: "blogs",
        component: () => import('@/pages/blog/blogs.vue'),
      },
      {
        path: "blog-detail",
        name: "blog-details",
        component: () => import('@/pages/blog/blog-detail.vue'),
      },
      {
        path: "blog-detail/:id",
        name: "blog-detail",
        component: () => import('@/pages/blog/blog-detail.vue'),
      },
      {
        path: "comingsoon",
        name: "comingsoon",
        component: () => import('@/pages/special-pages/comingsoon.vue'),
      },
      {
        path: "maintenance",
        name: "maintenance",
        component: () => import('@/pages/special-pages/maintenance.vue'),
      },
      {
        path: "thankyou",
        name: "thankyou",
        component: () => import('@/pages/special-pages/thankyou.vue'),
      },
      {
        path: "signup",
        name: "signup",
        beforeEnter: redirectToDashboard('/auth/register'),
      },
      {
        path: "/auth/login",
        name: "login",
        beforeEnter: redirectToDashboard('/auth/login'),
      },
      {
        path: "/:lang(en|es)/:error(.*)", // Incluye el parámetro lang
        name: "error",
        component: () => import('@/pages/special-pages/error.vue'),
      }      
    ],
  },
  {
    path: "/:catchAll(.*)", // Redirigir a la versión en inglés por defecto
    redirect: "/en",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authMiddleware);

export default router;
